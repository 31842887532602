<template>
  <div id="app" class="father">
    <!-- 1.日历与Logo公共部分封装为组件 -->
    <!-- 2.走马灯           30000-->
    <el-carousel
      :interval="10000"
      :autoplay="true"
      style="height: 100%"
      indicator-position="none"
    >
      <el-carousel-item v-for="page in pages" :key="page.name">
        <!-- 这里是每个页面组件的内容 -->
        <KeepAlive>
          <component :is="page.component"></component>
        </KeepAlive>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import "element-ui/lib/theme-chalk/index.css";
export default {
  name: "App",
  data() {
    return {
      pages: [
        {
          name: "SmartFactory", //光伏技术参数
          component: () => import("./view/SmartFactory.vue"),
        },
        {
          name: "SolarPphotovoltaics", //光伏发电
          component: () => import("./view/SolarPphotovoltaics.vue"),
        },
        {
          name: "realTimePower", //实时发电
          component: () => import("./view/realTimePower.vue"),
        },
        {
          name: "CurrentMounth", //当月发电
          component: () => import("./view/CurrentMounth.vue"),
        },
        {
          name: "CurrentYear", //当年发电
          component: () => import("./view/CurrentYear.vue"),
        },
        {
          name: "TotalBills", //发电数据
          component: () => import("./view/TotalBills.vue"),
        },
        {
          name: "ReduceCarbonEmissions",
          component: () => import("./view/ReduceCarbonEmissions.vue"),
        },
        {
          name: "ReduceCarbonEmissions2",
          component: () => import("./view/ReduceCarbonEmissions2.vue"),
        },
        {
          name: "ReduceCarbonEmissions3",
          component: () => import("./view/ReduceCarbonEmissions3.vue"),
        },
        {
          name: "WasteWater", //废水处理
          component: () => import("./view/WasteWater.vue"),
        },
        // {
        //   name: "RealTimeWaterVolume",   //实时处理水量
        //   component: () => import("./view/realTimeWaterVolume.vue"),
        // },

        {
          name: "CurrentMounthWater", //当月处理水量
          component: () => import("./view/CurrentMounthWater.vue"),
        },
        {
          name: "CurrentYearWater", //当月处理水量
          component: () => import("./view/CurrentYearWater.vue"),
        },
        // {
        //   name: "TotalSave",   //累计节约水量
        //   component: () => import("./view/TotalSave.vue"),
        // },
      ],
    };
  },
};
</script>

<style>
/* 过渡效果 */
.el-carousel__item.is-animating {
  transition: none;
}
#app {
  font-family: "Microsoft YaHei", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100vh; /* 使用视口高度，充满整个屏幕 */
}
/* 移除body元素的外边距 */
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* html, body { max-width: 100%; overflow-x: hidden; }  */
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
.el-carousel__container {
  height: 100%;
}
</style>
