import Vue from 'vue'
import App from './App.vue'
import { Calendar ,Carousel,CarouselItem   } from 'element-ui'
import store from './store/store'
// 引入echarts
import echarts from "./echarts";
import '@/style/index.css'
//引入日历组件，注册为全局组件
import calendarPage from '@/components/calendarPage'
import LogoPage from '@/components/LogoPage'
Vue.component('calendarPage',calendarPage)
Vue.component('LogoPage',LogoPage)
// 挂载到vue实例中
Vue.prototype.$echarts = echarts
Vue.use(Calendar)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  store
}).$mount('#app')
