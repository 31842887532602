<!-- 日历组件 -->
<template>
    <div class="calendar">
        <div class="calenderTitle">{{ `${year}-${month}-${day} ${hours}:${minutes}` }}</div>
        <div class="week">
          <span v-for="item,index in weekHeader" :key="index">{{ item }}</span>
        </div>
        <el-calendar v-model="currentDate"  :first-day-of-week="1" :formatter="customFormatter">
        </el-calendar>
    </div>
</template>
<script>
import { mapGetters ,mapState } from "vuex";
export default {
  name: 'calendarPage',
  computed: {
    ...mapGetters(['year','month','day','hours','minutes','lastDayOfMonth']),
    ...mapState(['currentDate'])
  },
  data() {
      return {
        // value: new Date(),
        currentTime:'',
        timer:null,
        weekHeader: ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'],
      }
    },
  methods:{
    updateTime() {
      // 每隔一秒更新一次时间
      this.$store.commit("setCurrentDate");
    },
    customFormatter(date) {
      const weekday = date.getDay();
      return this.weekHeader[weekday];
    }
  },
  mounted(){
    this.timer = setInterval(this.updateTime, 1000);
  },
  beforeDestroy(){
    clearTimeout(this.timer)
  }
}
</script>

<style scoped>
.week{
  color: #fff;
  display: flex;
  margin-top: 1.0417vw;
  font-size: .8333vw;
  font-weight: 700;
  justify-content: space-around;
  align-items: center;
}
.calendar{
  width: 25vw;
  position: absolute;
  bottom:10vh
}
.calendar .calenderTitle{
  display: flex;
  justify-content: start;
  color: #fff;
  font-weight: 700;
  font-size: 1vw;
  padding-left: 1vw;
}
.calendar >>>.el-calendar__header .el-calendar__title{
  display: none;
}
.calendar >>>.el-calendar__body table thead{
  display: none;
}
.calendar >>>.el-calendar__button-group{
  display: none;
}
/* 去掉边框 */
.calendar >>>.el-calendar-table td{
  border: none;
}
.calendar >>>.el-calendar__header{
  border: none;
  padding: 0;
}
.calendar >>>.el-calendar__body{
  padding: 0;
}
.calendar >>>.el-calendar{
  background: transparent;
}
.calendar >>>.el-calendar-table thead th{
  color: #fff;
}
.calendar >>>.el-calendar-table .el-calendar-day{
  font-size: .9375vw;
  font-weight: 700;
  text-align: center;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  height: 3.6458vw
}
/* 取消上个月和下个月显示 */
::v-deep .el-calendar-table:not(.is-range) td.next {
    display: none;
  }
::v-deep .el-calendar-table:not(.is-range) td.prev {
    visibility: hidden;
  }
/* 选中当前日期的状态 */
.calendar >>> .el-calendar-table td.is-selected {
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4); /* 阴影效果 */
  /* 
     第一个值：水平偏移量（正值向右偏移，负值向左偏移）
     第二个值：垂直偏移量（正值向下偏移，负值向上偏移）
     第三个值：模糊半径（增加此值会增加模糊效果）
     第四个值：阴影颜色（使用 RGBA 值，最后一个参数表示阴影的不透明度）
  */
}
.calendar >>> .is-selected .el-calendar-day  span {
  color: black;  /* 选中日期颜色 */
}
/* 悬停日期时的 */
.calendar >>> .el-calendar-table .el-calendar-day:hover{
  background:none;
}
/* 过渡效果 */
.calendar >>> .el-calendar-table td{
  transition: none;

}
/* 自定义表头 */
</style>
