import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    currentDate: new Date(),
    existingArray: [],
  },
  mutations: {
    setCurrentDate(state) {
      const newDate = new Date();
      state.currentDate = newDate;
    },
  },
  getters: {
    year(state) {
      return state.currentDate.getFullYear().toString().padStart(4, "0");
    },
    month(state) {
      return (state.currentDate.getMonth() + 1).toString().padStart(2, "0");
    },
    day(state) {
      return state.currentDate.getDate().toString().padStart(2, "0");
    },
    hours(state) {
      return state.currentDate.getHours().toString().padStart(2, "0");
    },
    minutes(state) {
      return state.currentDate.getMinutes().toString().padStart(2, "0");
    },
    countMonths(state) {
      state.existingArray = []; // 清空数组
      const lastDayOfMonth = new Date(
        state.currentDate.getFullYear(),
        state.currentDate.getMonth() + 1,
        0
      ).getDate();
      for (let i = 1; i <= lastDayOfMonth; i++) {
        state.existingArray.push(i);
      }
      return state.existingArray;
    },
  },
});

export default store;
